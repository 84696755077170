import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import _ from 'lodash'
// import { Api, FC } from '../../Services'
// import { cap } from '../../State'
import React from 'react'
import { FC } from '@Common/Services'

const debounce = _.debounce((editor, id, onChange) => {
  const data = '<div class="ck-content">' + editor.getData() + '</div>'
  onChange({ [id]: data })
}, 600)

const Editor = ({ id, onChange, initialValue, imageHandler, noDebounce, entityObj = false, disabled }) => {
  function MyCustomUploadAdapterPlugin (editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new MyUploadAdapter({ loader, entityObj })
    }
  }

  const customConfig = {
    toolbar: [
      'fontfamily',
      'fontsize',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      '|',
      'alignment',
      '|',
      'numberedList',
      'bulletedList',
      '|',
      'outdent',
      'indent',
      '|',
      'link',
      'blockquote',
      ...((imageHandler && entityObj) ? ['uploadImage'] : []),
      'insertTable',
      '|',
      'undo',
      'redo'
    ],
    extraPlugins: [MyCustomUploadAdapterPlugin]
  }
  return (
    <div style={{ backgroundColor: 'white', width: '100%', borderRadius: 20, color: 'black' }}>
      <CKEditor
        disabled={disabled}
        onReady={editor => {
          editor?.ui?.getEditableElement()?.parentElement?.insertBefore(
            editor?.ui?.view?.toolbar?.element,
            editor?.ui?.getEditableElement()
          )
        }}
        onError={({ willEditorRestart }) => {
          if (willEditorRestart) {
            this.editor?.ui?.view?.toolbar?.element?.remove()
          }
        }}
        config={customConfig}
        onChange={(event, editor) => {
          if (noDebounce) {
            const data = '<div class="ck-content">' + editor.getData() + '</div>'
            onChange({ [id]: data })
          } else {
            debounce(editor, id, onChange)
          }
        }}
        editor={DecoupledEditor}
        data={initialValue}
      />
    </div>
  )
}

class MyUploadAdapter {
  constructor (props) {
    // CKEditor 5's FileLoader instance.
    this.loader = props.loader
    this.entityObj = props.entityObj
    this.errorImage = { default: 'https://developers.google.com/maps/documentation/maps-static/images/error-image-generic.png?hl=it' }
    // URL where to send files.
  }

  // Starts the upload process.
  async upload () {
    try {
      const base64 = this.loader._reader._data
      return this.loader.file.then(({ name, type }) => this.uploadFile({ name, type }, base64))
    } catch (e) {
      console.log('ERR => ', e)
      return this.errorImage
    }
  }

  // Aborts the upload process.
  abort () {

  }

  async uploadFile (file, base64) {
    // const cap = { entity: '', entityId: '' }
    // const { entity, entityId } = cap // cap.entity.state
    try {
      const uri = base64
      const res = await FC.client
        .service('upload')
        .create({ uri }, {
          query: this.entityObj
        })
      const data = {
        file: file.name,
        format: file.type,
        from: 'BACKEND_APPTOUR'
      }
      const documentsRes = await FC.service('documents').patch(res.documentId, data)

      return { default: documentsRes.uri }
    } catch (e) {
      console.log('ERRORE => ', e)
      window.growl.show({
        severity: 'error',
        summary: 'Errore immagine inserita',
        detail: e.message === 'big' ? 'File troppo grande ' : 'Errore caricamento immagine'
      })
    }
    return this.errorImage
  }
}

export { Editor }
