/* eslint-disable no-console */
import feathers from '@feathersjs/client'
import socketio from '@feathersjs/socketio-client'
import fcAuth from '@feathersjs/authentication-client'
import io from 'socket.io-client/dist/socket.io'
import { isNumber } from 'lodash'
import * as Sentry from '@sentry/react'
import { eventsHandler, loginHandler, mailHandler, messageHandler, newQuotesHandler, patchedQuoteHandler } from './FeatherEvents'
import { invalidateQuery } from '@Common/Services'

/* ============================== Socket Configuration ================================================== */
const API_BASE_URL = window?._env_?.API_BASE_URL || 'http://localhost:3000'

const FC = {
  authenticated: false,
  socketConfig: {
    timeout: 60000,
    pingInterval: 20000,
    pingTimeout: 50000
  },
  isDev: import.meta.env.DEV,
  socket: io(API_BASE_URL),
  client: feathers(),
  licensePing: false,
  licenseAgency: '',
  online: false,
  connectionHandler: (event) => () => {
    FC.isDev && console.log(`Socket ${event} to ${API_BASE_URL}`)
    FC.online = event === 'connect'
  }
}

FC.client.configure(socketio(FC.socket, FC.socketConfig))
FC.client.configure(fcAuth({ storage: new fcAuth.MemoryStorage() }))
FC.socket.on('connect', FC.connectionHandler('connect'))
FC.socket.on('disconnect', FC.connectionHandler('disconnect'))

/* ============================== Socket Methods ================================================== */
FC.addListeners = () => {
  FC.client.service('messages').on('created', messageHandler)
  FC.client.service('quotes').on('created', newQuotesHandler)
  FC.client.service('sendMail').on('created', mailHandler)
  FC.client.service('logins').on('patched', loginHandler)
  FC.client.service('events').on('created', eventsHandler)
  FC.client.service('quotes').on('patched', patchedQuoteHandler)
  FC.licensePing = setInterval(() => FC.socket.status && FC.service('logins').get(FC.licenseAgency), 120000)
  FC.isDev && console.log('FC Authenticated & Listening for events')
}

FC.stopListeners = () => {
  FC.isDev && FC.socket.removeAllListeners()
  FC.client.service('messages').removeListener('created')
  FC.client.service('quotes').removeListener('created')
  FC.client.service('sendMail').removeListener('created')
  FC.client.service('logins').removeListener('patched')
  FC.client.service('events').removeListener('created')
  FC.client.service('quotes').removeListener('patched')
  clearInterval(FC.licensePing) && (FC.licensePing = false)
  FC.isDev && console.log('FC Stopped listening for events')
}

FC.login = async (credentials) => {
  try {
    const response = await FC.client.authenticate(credentials)
    Sentry.setUser(response.user)

    FC.licenseAgency = response.user.agency
    FC.authenticated = !!response.accessToken && !response.invalidAuth
    FC.authenticated && FC.addListeners()
    response.user.agency !== 'empty' && FC.addLoginCounter(response.user.agency)
    invalidateQuery(['agency', 'user'])
    return response
  } catch (details) {
    FC.error = details
    return false
  }
}

FC.logout = async () => {
  FC.stopListeners()
  FC.client.logout()
  FC.authenticated = false
}

const doServiceMethod = async (serviceName, method, param1, param2) => {
  try {
    if (!param1 && !param2) return await FC.client.service(serviceName)[method]()
    if (!param2) return await FC.client.service(serviceName)[method](param1)
    return await FC.client.service(serviceName)[method](param1, param2)
  } catch (error) {
    return undefined
  }
}

FC.addLoginCounter = async (agencyId) => {
  try {
    const agency = await FC.service('agencies').get(agencyId)
    const loginCount = agency.loginCount && isNumber(agency.loginCount) ? agency.loginCount + 1 : 1
    FC.service('agencies').patch(agencyId, { loginCount, loginLast: new Date() })
  } catch (e) { }
}

FC.service = (serviceName) => ({
  find: (query) => doServiceMethod(serviceName, 'find', query),
  patch: (id, body) => doServiceMethod(serviceName, 'patch', id, body),
  get: (id, query) => doServiceMethod(serviceName, 'get', id, query),
  create: (body, query) => doServiceMethod(serviceName, 'create', body, query),
  remove: (id) => doServiceMethod(serviceName, 'remove', id)
})

/* ============================== Socket Actions ================================================== */

FC.isReady = () => FC.online && FC.authenticated

export { FC }
